.custom-scroll {
  max-height: 70vh;
  overflow-x: auto;
  overflow-y: auto;
}
.td-num {
  text-align: right;
}
.posi-cont-submenu {
  margin-top: 92px;
  padding-left: 17rem;
}
