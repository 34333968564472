@import url("https://fonts.googleapis.com/css2?family=Headland+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

.sidebar .nav-item.active {
  /* background: #F8FCFF; */
  /* border-radius: 20px 0px 0px 20px; */
}
.sidebar .nav-item .nav-link.active {
  font-weight: 700;
}
.sidebar .nav-item .nav-link.active {
  background: #f8fcff;
  border-radius: 20px 0px 0px 20px;
  color: #1c374b;
}

.sidebar-dark .nav-item.active .nav-link,
.sidebar-dark .nav-item.active .nav-link i {
  color: #1c374b;
}
.sidebar-dark .nav-item .nav-link.active,
.sidebar-dark .nav-item .nav-link.active i {
  color: #1c374b;
}

.sidebar .nav-item .nav-link {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.7rem;
}
div.nav-sub {
  margin-left: 20px;
  margin-top: 32px;
}
.error-span {
  color: #d32f2f;
  font-size: 13px;
}
.warning-span {
  color: darkorange;
  font-size: x-small;
}
.skelton-auto-complete {
  margin-top: 13px;
}
.component-scroll-y {
  height: 360px;
  /* overflow-y: scroll; */
}
.border-1 {
  border: 1px solid #d2d2d2;
  margin-bottom: 10px;
}
.component-scroll-y .card-header {
  /* padding: 10px 0px 10px 0px; */
}
.error-span-check {
  color: #d32f2f;
  font-size: 13px;
  margin-top: -6px;
}
.error-span-radio {
  color: #d32f2f;
  font-size: 13px;
  margin-top: -15px;
}
div.scrollmenusub {
  /* background-color: #010407; */
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-left: 10%;
  width: 100%;
  border-radius: 10px;
  padding: 7px;
  justify-content: center;
}

div.scrollmenusub a {
  /* display: inline-block; */
  min-width: 250px;
  text-align: center;
  padding: 9px 14px;
  text-decoration: none;
  margin-left: 10px;

  font-size: 16px;
  color: #788d9e;
}

div.scrollmenusub a.active,
div.scrollmenusub a:hover {
  border-bottom: 3px solid #1c374b;
  color: #1c374b;
}

div.scrollmenusub a.disabledNavBarLink,
div.scrollmenusub a.disabledNavBarLink:hover {
  background-color: #4e6373;
  border-radius: 8px;
  color: #9f9c9c;
  cursor: not-allowed;
}
div.scrollmenusub {
  margin-left: 0;
  /* width: 51%; */
}
div.scrollmenu {
  background-color: #4e6373;

  overflow: auto;
  white-space: nowrap;
  margin-left: 10%;
  width: 100%;
  border-radius: 10px;
  padding: 7px;
}

div.scrollmenu a {
  display: inline-block;
  text-align: center;
  padding: 9px 14px;
  text-decoration: none;
  margin-left: 10px;

  font-size: 14px;
  color: #fff;
}

div.scrollmenu a.active,
div.scrollmenu a:hover {
  background: #1c374b;
  border-radius: 8px;
  color: #fff;
}
div.scrollmenu a.disabledNavBarLink,
div.scrollmenu a.disabledNavBarLink:hover {
  background-color: #4e6373;
  border-radius: 8px;
  color: #9f9c9c;
  cursor: not-allowed;
}

.posi-cont {
  margin-top: 92px;
  padding-left: 17.5rem;
}
.posi-cont-second {
  margin-top: 32px;
  padding-left: 17.5rem;
}

.sidebar-toggled .fixed-top {
  left: 0;
}
.sidebar-toggled .posi-cont {
  padding-left: 1.5rem;
}
.sidebar-toggled .posi-cont-second {
  padding-left: 1.5rem;
}
.fixed-top {
  left: 0;
}

.sidebar {
  position: fixed;
  margin-top: 70px;
}
.card-header {
  border-bottom: none;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2rem + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #616463;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.small-control {
  height: calc(1.5rem + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #616463;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0.35rem;
}
label {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #616463;
  font-size: 15px;
}
option[value=""][disabled] {
  color: #d2d2d2 !important;
}
.card {
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #cee2f1;
  box-shadow: 21px 324px 130px rgb(25 66 50 / 1%),
    12px 182px 110px rgb(25 66 50 / 2%), 5px 81px 81px rgb(25 66 50 / 3%),
    1px 20px 45px rgb(25 66 50 / 4%), 0px 0px 0px rgb(25 66 50 / 4%);
  border-radius: 15px;
}
.topbar {
  border-bottom: 1px solid #1c374b;
}
a.sidebar-brand {
  width: 20rem;
}
div.scrollmenu {
  margin-left: 0;
  /* width: 51%; */
}
.text-primary {
  /* font-family: HeadlandOne; */
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #1c374b !important;
  text-align: left;
}
.form-group p {
  color: #bcbcbc;
  font-size: 12px;
  margin-top: -5px;
}
.card-header {
  padding: 32px 32px 20px 32px;
}
.card-body {
  padding: 1.25rem 32px;
}
.card-body-price-status-page {
  padding: 12px;
}
.account-info {
  background-color: #edf5f2;
  border-color: #edf5f2;
  color: #1c374b;
  padding: 2px 10px;
  border-radius: 10px;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #d2d2d2;
  background: transparent;
  border: 0;
}
.bootstrap-select .dropdown-toggle .filter-option {
  border: 1px #ccc solid;
  border-radius: 4px;
  height: calc(2rem + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}
.bootstrap-select > .dropdown-toggle {
  border: 0;
  background: transparent;
  height: calc(2rem + 0.75rem + 2px);
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
  font-size: 14px;
  line-height: 32px;
}
.show > .btn-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #f8fcff;
  border-color: #cbd3e9;
}
select,
.dropdown-toggle::after {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url("../../images/chevron-down.svg");
  background-size: 25px auto;
  background-repeat: no-repeat;
  background-position: right;
}
.dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  pointer-events: none;
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  color: transparent;
  background-image: url("../../images/chevron-down.svg");
  right: 3px;
  top: 6px;
  height: 34px;
  background-size: 20px auto;
  padding: 15px 0px 0px 15px;
  position: absolute;
  pointer-events: none;
}
tr:nth-child(even) {
  background-color: #fff;
}
tr.selected {
  background-color: #f8f7fa;
}
.changed {
  color: #bc4d0e;
}
.form-controldr {
  display: block;
  height: calc(2rem + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  width: 50%;
  line-height: 1.5;
  color: #616463;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.colu1 {
  border-radius: 4px 0px 0px 0px !important;
  text-align: left;
}
.colu1 label {
  color: #fff;
}
.btn-colo {
  background-color: #edf5f2;
  border-color: #edf5f2;
  color: #1c374b;
}
.colu2 {
  border-radius: 0px 4px 0px 0px !important;
}
.abtn {
  display: inline-block;
  color: #616463;
  text-align: center;
  padding: 9px;
  font-family: "Inter", sans-serif;
  margin: 6px;
  text-decoration: none;
  border: 2px solid #d2d2d2;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
}
.txt {
  margin-left: 20px !important;
}
.chg {
  margin-top: 10px;
}
.abton {
  display: inline-block;
  color: #ffffff;
  text-align: center;
  font-family: "Inter", sans-serif;
  padding: 9px;
  text-decoration: none;
  background-color: #1c374b;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  border: none;
}
.click {
  margin-left: 43%;
  margin-top: 24px;
}
.btn-primary {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
  margin-left: 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}
.btn-secondary {
  color: #1c374b;
  background-color: #f8f7fa !important;
  border-color: #1c374b;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.btn-abtn {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
  margin-bottom: 32px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .posi-cont {
    padding-left: 10rem;
  }
  .posi-cont-second {
    padding-left: 10rem;
  }
  .text-primary {
    font-size: 19px;
  }
  .form-controldr {
    width: 100%;
  }
  .card-body {
    padding: 17px;
  }
  .card-header {
    padding: 15px;
  }
  a.sidebar-brand {
    width: auto;
  }
}
