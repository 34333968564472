:root {
  --blue: #1c374b;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e74a3b;
  --orange: #fd7e14;
  --yellow: #f6c23e;
  --green: #1cc88a;
  --teal: #20c9a6;
  --cyan: #36b9cc;
  --white: #fff;
  --gray: #858796;
  --gray-dark: #5a5c69;
  --primary: #1c374b;
  --secondary: #858796;
  --success: #1cc88a;
  --info: #36b9cc;
  --warning: #f6c23e;
  --danger: #e74a3b;
  --light: #f8f9fc;
  --dark: #5a5c69;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: font-family: 'Inter', sans-serif;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1c374b;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #224abe;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #858796;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #858796;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 80%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dddfeb;
  border-radius: 0.35rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #858796;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #3a3b45;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #3a3b45;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
      max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
      max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
      max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
      max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
  }
  .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
  }
  .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
  }
  .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
  }
  .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
  }
  .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
  }
  .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
  }
  .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
  }
  .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
  }
  .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
  }
  .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .order-sm-first {
      order: -1;
  }
  .order-sm-last {
      order: 13;
  }
  .order-sm-0 {
      order: 0;
  }
  .order-sm-1 {
      order: 1;
  }
  .order-sm-2 {
      order: 2;
  }
  .order-sm-3 {
      order: 3;
  }
  .order-sm-4 {
      order: 4;
  }
  .order-sm-5 {
      order: 5;
  }
  .order-sm-6 {
      order: 6;
  }
  .order-sm-7 {
      order: 7;
  }
  .order-sm-8 {
      order: 8;
  }
  .order-sm-9 {
      order: 9;
  }
  .order-sm-10 {
      order: 10;
  }
  .order-sm-11 {
      order: 11;
  }
  .order-sm-12 {
      order: 12;
  }
  .offset-sm-0 {
      margin-left: 0;
  }
  .offset-sm-1 {
      margin-left: 8.33333%;
  }
  .offset-sm-2 {
      margin-left: 16.66667%;
  }
  .offset-sm-3 {
      margin-left: 25%;
  }
  .offset-sm-4 {
      margin-left: 33.33333%;
  }
  .offset-sm-5 {
      margin-left: 41.66667%;
  }
  .offset-sm-6 {
      margin-left: 50%;
  }
  .offset-sm-7 {
      margin-left: 58.33333%;
  }
  .offset-sm-8 {
      margin-left: 66.66667%;
  }
  .offset-sm-9 {
      margin-left: 75%;
  }
  .offset-sm-10 {
      margin-left: 83.33333%;
  }
  .offset-sm-11 {
      margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
  }
  .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
  }
  .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
  }
  .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
  }
  .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
  }
  .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
  }
  .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
  }
  .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
  }
  .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
  }
  .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
  }
  .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .order-md-first {
      order: -1;
  }
  .order-md-last {
      order: 13;
  }
  .order-md-0 {
      order: 0;
  }
  .order-md-1 {
      order: 1;
  }
  .order-md-2 {
      order: 2;
  }
  .order-md-3 {
      order: 3;
  }
  .order-md-4 {
      order: 4;
  }
  .order-md-5 {
      order: 5;
  }
  .order-md-6 {
      order: 6;
  }
  .order-md-7 {
      order: 7;
  }
  .order-md-8 {
      order: 8;
  }
  .order-md-9 {
      order: 9;
  }
  .order-md-10 {
      order: 10;
  }
  .order-md-11 {
      order: 11;
  }
  .order-md-12 {
      order: 12;
  }
  .offset-md-0 {
      margin-left: 0;
  }
  .offset-md-1 {
      margin-left: 8.33333%;
  }
  .offset-md-2 {
      margin-left: 16.66667%;
  }
  .offset-md-3 {
      margin-left: 25%;
  }
  .offset-md-4 {
      margin-left: 33.33333%;
  }
  .offset-md-5 {
      margin-left: 41.66667%;
  }
  .offset-md-6 {
      margin-left: 50%;
  }
  .offset-md-7 {
      margin-left: 58.33333%;
  }
  .offset-md-8 {
      margin-left: 66.66667%;
  }
  .offset-md-9 {
      margin-left: 75%;
  }
  .offset-md-10 {
      margin-left: 83.33333%;
  }
  .offset-md-11 {
      margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
  }
  .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
  }
  .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
  }
  .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
  }
  .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
  }
  .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
  }
  .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
  }
  .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
  }
  .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
  }
  .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
  }
  .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .order-lg-first {
      order: -1;
  }
  .order-lg-last {
      order: 13;
  }
  .order-lg-0 {
      order: 0;
  }
  .order-lg-1 {
      order: 1;
  }
  .order-lg-2 {
      order: 2;
  }
  .order-lg-3 {
      order: 3;
  }
  .order-lg-4 {
      order: 4;
  }
  .order-lg-5 {
      order: 5;
  }
  .order-lg-6 {
      order: 6;
  }
  .order-lg-7 {
      order: 7;
  }
  .order-lg-8 {
      order: 8;
  }
  .order-lg-9 {
      order: 9;
  }
  .order-lg-10 {
      order: 10;
  }
  .order-lg-11 {
      order: 11;
  }
  .order-lg-12 {
      order: 12;
  }
  .offset-lg-0 {
      margin-left: 0;
  }
  .offset-lg-1 {
      margin-left: 8.33333%;
  }
  .offset-lg-2 {
      margin-left: 16.66667%;
  }
  .offset-lg-3 {
      margin-left: 25%;
  }
  .offset-lg-4 {
      margin-left: 33.33333%;
  }
  .offset-lg-5 {
      margin-left: 41.66667%;
  }
  .offset-lg-6 {
      margin-left: 50%;
  }
  .offset-lg-7 {
      margin-left: 58.33333%;
  }
  .offset-lg-8 {
      margin-left: 66.66667%;
  }
  .offset-lg-9 {
      margin-left: 75%;
  }
  .offset-lg-10 {
      margin-left: 83.33333%;
  }
  .offset-lg-11 {
      margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
  }
  .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
  }
  .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
  }
  .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
  }
  .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
  }
  .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
  }
  .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
  }
  .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
  }
  .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
  }
  .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
  }
  .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
  }
  .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .order-xl-first {
      order: -1;
  }
  .order-xl-last {
      order: 13;
  }
  .order-xl-0 {
      order: 0;
  }
  .order-xl-1 {
      order: 1;
  }
  .order-xl-2 {
      order: 2;
  }
  .order-xl-3 {
      order: 3;
  }
  .order-xl-4 {
      order: 4;
  }
  .order-xl-5 {
      order: 5;
  }
  .order-xl-6 {
      order: 6;
  }
  .order-xl-7 {
      order: 7;
  }
  .order-xl-8 {
      order: 8;
  }
  .order-xl-9 {
      order: 9;
  }
  .order-xl-10 {
      order: 10;
  }
  .order-xl-11 {
      order: 11;
  }
  .order-xl-12 {
      order: 12;
  }
  .offset-xl-0 {
      margin-left: 0;
  }
  .offset-xl-1 {
      margin-left: 8.33333%;
  }
  .offset-xl-2 {
      margin-left: 16.66667%;
  }
  .offset-xl-3 {
      margin-left: 25%;
  }
  .offset-xl-4 {
      margin-left: 33.33333%;
  }
  .offset-xl-5 {
      margin-left: 41.66667%;
  }
  .offset-xl-6 {
      margin-left: 50%;
  }
  .offset-xl-7 {
      margin-left: 58.33333%;
  }
  .offset-xl-8 {
      margin-left: 66.66667%;
  }
  .offset-xl-9 {
      margin-left: 75%;
  }
  .offset-xl-10 {
      margin-left: 83.33333%;
  }
  .offset-xl-11 {
      margin-left: 91.66667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #858796;
}
.table td,
.table th {
  padding: 0.75rem;
  /* vertical-align: top; */
  border-top: 1px solid #e3e6f0;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}
.table tbody + tbody {
  border-top: 2px solid #e3e6f0;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #e3e6f0;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e3e6f0;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #858796;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #cdd8f6;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #a3b6ee;
}
.table-hover .table-primary:hover {
  background-color: #b7c7f2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b7c7f2;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #dddde2;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #c0c1c8;
}
.table-hover .table-secondary:hover {
  background-color: #cfcfd6;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfcfd6;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #bff0de;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #89e2c2;
}
.table-hover .table-success:hover {
  background-color: #aaebd3;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aaebd3;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #c7ebf1;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #96dbe4;
}
.table-hover .table-info:hover {
  background-color: #b3e4ec;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e4ec;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fceec9;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #fadf9b;
}
.table-hover .table-warning:hover {
  background-color: #fbe6b1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbe6b1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f8ccc8;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #f3a199;
}
.table-hover .table-danger:hover {
  background-color: #f5b7b1;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b7b1;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfd;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #d1d1d5;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #a9aab1;
}
.table-hover .table-dark:hover {
  background-color: #c4c4c9;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c4c4c9;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #5a5c69;
  border-color: #6c6e7e;
}
.table .thead-light th {
  color: #6e707e;
  background-color: #eaecf4;
  border-color: #e3e6f0;
}
.table-dark {
  color: #fff;
  background-color: #5a5c69;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #6c6e7e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
      border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
      border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
      border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
      border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
      transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6e707e;
}
.form-control:focus {
  color: #6e707e;
  background-color: #fff;
  border-color: #bac8f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #D2D2D2;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #D2D2D2;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #D2D2D2;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #D2D2D2;
  opacity: 1;
}
.form-control::placeholder {
  color: #D2D2D2;
  opacity: 1;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
select,
select option {
color: #616463;
}
select:invalid,
select option[value=""] {
color: #D2D2D2;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #eaecf4;
  opacity: 1;
}
input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #6e707e;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #858796;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #858796;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1cc88a;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(28, 200, 138, 0.9);
  border-radius: 0.35rem;
}
.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #1cc88a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231cc88a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #1cc88a;
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #1cc88a;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat,
      #fff
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231cc88a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
          center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #1cc88a;
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #1cc88a;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #1cc88a;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #1cc88a;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #34e3a4;
  background-color: #34e3a4;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1cc88a;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #1cc88a;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #1cc88a;
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e74a3b;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(231, 74, 59, 0.9);
  border-radius: 0.35rem;
}
.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #e74a3b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e74a3b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e74a3b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #e74a3b;
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #e74a3b;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat,
      #fff
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e74a3b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e74a3b' stroke='none'/%3e%3c/svg%3e")
          center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #e74a3b;
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #e74a3b;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #e74a3b;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #e74a3b;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #ed7468;
  background-color: #ed7468;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e74a3b;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #e74a3b;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #e74a3b;
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
  }
  .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
  }
  .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
      display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
      width: auto;
  }
  .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
  }
  .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
  }
  .form-inline .custom-control {
      align-items: center;
      justify-content: center;
  }
  .form-inline .custom-control-label {
      margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #858796;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
      transition: none;
  }
}
.btn:hover {
  color: #858796;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
  box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0F75BC;
  border-color: #244ec9;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-secondary:hover {

  border-color: #6b6d7d;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #717384;
  border-color: #6b6d7d;
  box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6b6d7d;
  border-color: #656776;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}
.btn-success:hover {
  color: #fff;
  background-color: #17a673;
  border-color: #169b6b;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #17a673;
  border-color: #169b6b;
  box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #169b6b;
  border-color: #149063;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}
.btn-info:hover {
  color: #fff;
  background-color: #2c9faf;
  border-color: #2a96a5;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #2c9faf;
  border-color: #2a96a5;
  box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2a96a5;
  border-color: #278c9b;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
}
.btn-warning {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}
.btn-warning:hover {
  color: #fff;
  background-color: #f4b619;
  border-color: #f4b30d;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #f4b619;
  border-color: #f4b30d;
  box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4b30d;
  border-color: #e9aa0b;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.btn-danger:hover {
  color: #fff;
  background-color: #e02d1b;
  border-color: #d52a1a;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #e02d1b;
  border-color: #d52a1a;
  box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d52a1a;
  border-color: #ca2819;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
}
.btn-light {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-light:hover {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
}
.btn-light.focus,
.btn-light:focus {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
  box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #d4daed;
  border-color: #cbd3e9;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-dark:hover {
  color: #fff;
  background-color: #484a54;
  border-color: #42444e;
}
.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #484a54;
  border-color: #42444e;
  box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #42444e;
  border-color: #3d3e47;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}
.btn-outline-primary {
  color: #1c374b;
  border-color: #1c374b;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #1c374b;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}
.btn-outline-secondary {
  color: #858796;
  border-color: #858796;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #858796;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}
.btn-outline-success {
  color: #1cc88a;
  border-color: #1cc88a;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #1cc88a;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}
.btn-outline-info {
  color: #36b9cc;
  border-color: #36b9cc;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #36b9cc;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}
.btn-outline-warning {
  color: #f6c23e;
  border-color: #f6c23e;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f6c23e;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}
.btn-outline-danger {
  color: #e74a3b;
  border-color: #e74a3b;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e74a3b;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}
.btn-outline-light {
  color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-outline-light:hover {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fc;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}
.btn-outline-dark {
  color: #5a5c69;
  border-color: #5a5c69;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #5a5c69;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #1c374b;
  text-decoration: none;
}
.btn-link:hover {
  color: #224abe;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #858796;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
      transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
      transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.85rem;
  color: #858796;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
      right: auto;
      left: 0;
  }
  .dropdown-menu-sm-right {
      right: 0;
      left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
      right: auto;
      left: 0;
  }
  .dropdown-menu-md-right {
      right: 0;
      left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
      right: auto;
      left: 0;
  }
  .dropdown-menu-lg-right {
      right: 0;
      left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
      right: auto;
      left: 0;
  }
  .dropdown-menu-xl-right {
      right: 0;
      left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eaecf4;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #3a3b45;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #2e2f37;
  text-decoration: none;
  background-color: #eaecf4;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1c374b;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #b7b9cc;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #858796;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #3a3b45;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after,
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after,
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .form-control:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1c374b;
  background-color: #1c374b;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #bac8f3;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #e5ebfa;
  border-color: #e5ebfa;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #858796;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #eaecf4;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #b7b9cc solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.35rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1c374b;
  background-color: #1c374b;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(78, 115, 223, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(78, 115, 223, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(78, 115, 223, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #b7b9cc;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
      transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(78, 115, 223, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #bac8f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.custom-select:focus::-ms-value {
  color: #6e707e;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #858796;
  background-color: #eaecf4;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6e707e;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #bac8f3;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #eaecf4;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #6e707e;
  content: "Browse";
  background-color: #eaecf4;
  border-left: inherit;
  border-radius: 0 0.35rem 0.35rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1c374b;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #e5ebfa;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dddfeb;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1c374b;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #e5ebfa;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dddfeb;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1c374b;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
      -ms-transition: none;
      transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #e5ebfa;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dddfeb;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dddfeb;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #b7b9cc;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #b7b9cc;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #b7b9cc;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
      transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #858796;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dddfeb;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #eaecf4 #eaecf4 #dddfeb;
}
.nav-tabs .nav-link.disabled {
  color: #858796;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #6e707e;
  background-color: #fff;
  border-color: #dddfeb #dddfeb #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.35rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1c374b;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}
.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
      flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
      display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
      flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
      display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
      flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
      display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
      flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
      display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius:15px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
}
.card-header:first-child {
  border-radius:15px;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fc;
  border-top: 1px solid #e3e6f0;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.35rem - 1px) calc(0.35rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.35rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}
.card-deck .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -0.75rem;
      margin-left: -0.75rem;
  }
  .card-deck .card {
      flex: 1 0 0%;
      margin-right: 0.75rem;
      margin-bottom: 0;
      margin-left: 0.75rem;
  }
}
.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
      display: flex;
      flex-flow: row wrap;
  }
  .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
  }
  .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
  }
  .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
      -moz-column-count: 3;
      column-count: 3;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
  }
  .card-columns .card {
      display: inline-block;
      width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #858796;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #858796;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.35rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1c374b;
  background-color: #fff;
  border: 1px solid #dddfeb;
}
.page-link:hover {
  z-index: 2;
  color: #224abe;
  text-decoration: none;
  background-color: #eaecf4;
  border-color: #dddfeb;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.page-item.disabled .page-link {
  color: #858796;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dddfeb;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
      transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #1c374b;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0F75BC;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #858796;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #6b6d7d;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #1cc88a;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #169b6b;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #36b9cc;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #2a96a5;
}
a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}
.badge-warning {
  color: #fff;
  background-color: #f6c23e;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #fff;
  background-color: #f4b30d;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #e74a3b;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #d52a1a;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}
.badge-light {
  color: #3a3b45;
  background-color: #f8f9fc;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #3a3b45;
  background-color: #d4daed;
}
a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #5a5c69;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #42444e;
}
a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eaecf4;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
      padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #293c74;
  background-color: #dce3f9;
  border-color: #cdd8f6;
}
.alert-primary hr {
  border-top-color: #b7c7f2;
}
.alert-primary .alert-link {
  color: #1c294e;
}
.alert-secondary {
  color: #45464e;
  background-color: #e7e7ea;
  border-color: #dddde2;
}
.alert-secondary hr {
  border-top-color: #cfcfd6;
}
.alert-secondary .alert-link {
  color: #2d2e33;
}
.alert-success {
  color: #0f6848;
  background-color: #d2f4e8;
  border-color: #bff0de;
}
.alert-success hr {
  border-top-color: #aaebd3;
}
.alert-success .alert-link {
  color: #093b29;
}
.alert-info {
  color: #1c606a;
  background-color: #d7f1f5;
  border-color: #c7ebf1;
}
.alert-info hr {
  border-top-color: #b3e4ec;
}
.alert-info .alert-link {
  color: #113b42;
}
.alert-warning {
  color: #806520;
  background-color: #fdf3d8;
  border-color: #fceec9;
}
.alert-warning hr {
  border-top-color: #fbe6b1;
}
.alert-warning .alert-link {
  color: #574516;
}
.alert-danger {
  color: #78261f;
  background-color: #fadbd8;
  border-color: #f8ccc8;
}
.alert-danger hr {
  border-top-color: #f5b7b1;
}
.alert-danger .alert-link {
  color: #4f1915;
}
.alert-light {
  color: #818183;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686869;
}
.alert-dark {
  color: #2f3037;
  background-color: #dedee1;
  border-color: #d1d1d5;
}
.alert-dark hr {
  border-top-color: #c4c4c9;
}
.alert-dark .alert-link {
  color: #18181c;
}
@-webkit-keyframes progress-bar-stripes {
  from {
      background-position: 1rem 0;
  }
  to {
      background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
      background-position: 1rem 0;
  }
  to {
      background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eaecf4;
  border-radius: 0.35rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1c374b;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
      transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.35rem;
}
.list-group-item-action {
  width: 100%;
  color: #6e707e;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #6e707e;
  text-decoration: none;
  background-color: #f8f9fc;
}
.list-group-item-action:active {
  color: #858796;
  background-color: #eaecf4;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #858796;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1c374b;
  border-color: #1c374b;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.35rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.35rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
      flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.35rem;
      border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.35rem;
      border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
      flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.35rem;
      border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.35rem;
      border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
      flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.35rem;
      border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.35rem;
      border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
      flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.35rem;
      border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.35rem;
      border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #293c74;
  background-color: #cdd8f6;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #293c74;
  background-color: #b7c7f2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #293c74;
  border-color: #293c74;
}
.list-group-item-secondary {
  color: #45464e;
  background-color: #dddde2;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #45464e;
  background-color: #cfcfd6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #45464e;
  border-color: #45464e;
}
.list-group-item-success {
  color: #0f6848;
  background-color: #bff0de;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #0f6848;
  background-color: #aaebd3;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f6848;
  border-color: #0f6848;
}
.list-group-item-info {
  color: #1c606a;
  background-color: #c7ebf1;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #1c606a;
  background-color: #b3e4ec;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1c606a;
  border-color: #1c606a;
}
.list-group-item-warning {
  color: #806520;
  background-color: #fceec9;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #806520;
  background-color: #fbe6b1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #806520;
  border-color: #806520;
}
.list-group-item-danger {
  color: #78261f;
  background-color: #f8ccc8;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #78261f;
  background-color: #f5b7b1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #78261f;
  border-color: #78261f;
}
.list-group-item-light {
  color: #818183;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818183;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818183;
  border-color: #818183;
}
.list-group-item-dark {
  color: #2f3037;
  background-color: #d1d1d5;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #2f3037;
  background-color: #c4c4c9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2f3037;
  border-color: #2f3037;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #858796;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
      transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e3e6f0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e3e6f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
  }
  .modal-sm {
      max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
      max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
      max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.35rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #858796;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
      transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
      transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
      transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
      transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
      transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
      transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
      transform: scale(0);
  }
  50% {
      opacity: 1;
      transform: none;
  }
}
@keyframes spinner-grow {
  0% {
      transform: scale(0);
  }
  50% {
      opacity: 1;
      transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #1c374b !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0F75BC !important;
}
.bg-secondary {
  background-color: #858796 !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #6b6d7d !important;
}
.bg-success {
  background-color: #1cc88a !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #169b6b !important;
}
.bg-info {
  background-color: #36b9cc !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #2a96a5 !important;
}
.bg-warning {
  background-color: #f6c23e !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #f4b30d !important;
}
.bg-danger {
  background-color: #e74a3b !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #d52a1a !important;
}
.bg-light {
  background-color: #f8f9fc !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #d4daed !important;
}
.bg-dark {
  background-color: #5a5c69 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #42444e !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #e3e6f0 !important;
}
.border-top {
  border-top: 1px solid #e3e6f0 !important;
}
.border-right {
  border-right: 1px solid #e3e6f0 !important;
}
.border-bottom {
  border-bottom: 1px solid #e3e6f0 !important;
}
.border-left {
  border-left: 1px solid #e3e6f0 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #1c374b !important;
}
.border-secondary {
  border-color: #858796 !important;
}
.border-success {
  border-color: #1cc88a !important;
}
.border-info {
  border-color: #36b9cc !important;
}
.border-warning {
  border-color: #f6c23e !important;
}
.border-danger {
  border-color: #e74a3b !important;
}
.border-light {
  border-color: #f8f9fc !important;
}
.border-dark {
  border-color: #5a5c69 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.35rem !important;
}
.rounded-top {
  border-top-left-radius: 0.35rem !important;
  border-top-right-radius: 0.35rem !important;
}
.rounded-right {
  border-top-right-radius: 0.35rem !important;
  border-bottom-right-radius: 0.35rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}
.rounded-left {
  border-top-left-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
      display: none !important;
  }
  .d-sm-inline {
      display: inline !important;
  }
  .d-sm-inline-block {
      display: inline-block !important;
  }
  .d-sm-block {
      display: block !important;
  }
  .d-sm-table {
      display: table !important;
  }
  .d-sm-table-row {
      display: table-row !important;
  }
  .d-sm-table-cell {
      display: table-cell !important;
  }
  .d-sm-flex {
      display: flex !important;
  }
  .d-sm-inline-flex {
      display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
      display: none !important;
  }
  .d-md-inline {
      display: inline !important;
  }
  .d-md-inline-block {
      display: inline-block !important;
  }
  .d-md-block {
      display: block !important;
  }
  .d-md-table {
      display: table !important;
  }
  .d-md-table-row {
      display: table-row !important;
  }
  .d-md-table-cell {
      display: table-cell !important;
  }
  .d-md-flex {
      display: flex !important;
  }
  .d-md-inline-flex {
      display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
      display: none !important;
  }
  .d-lg-inline {
      display: inline !important;
  }
  .d-lg-inline-block {
      display: inline-block !important;
  }
  .d-lg-block {
      display: block !important;
  }
  .d-lg-table {
      display: table !important;
  }
  .d-lg-table-row {
      display: table-row !important;
  }
  .d-lg-table-cell {
      display: table-cell !important;
  }
  .d-lg-flex {
      display: flex !important;
  }
  .d-lg-inline-flex {
      display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
      display: none !important;
  }
  .d-xl-inline {
      display: inline !important;
  }
  .d-xl-inline-block {
      display: inline-block !important;
  }
  .d-xl-block {
      display: block !important;
  }
  .d-xl-table {
      display: table !important;
  }
  .d-xl-table-row {
      display: table-row !important;
  }
  .d-xl-table-cell {
      display: table-cell !important;
  }
  .d-xl-flex {
      display: flex !important;
  }
  .d-xl-inline-flex {
      display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
      display: none !important;
  }
  .d-print-inline {
      display: inline !important;
  }
  .d-print-inline-block {
      display: inline-block !important;
  }
  .d-print-block {
      display: block !important;
  }
  .d-print-table {
      display: table !important;
  }
  .d-print-table-row {
      display: table-row !important;
  }
  .d-print-table-cell {
      display: table-cell !important;
  }
  .d-print-flex {
      display: flex !important;
  }
  .d-print-inline-flex {
      display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
      flex-direction: row !important;
  }
  .flex-sm-column {
      flex-direction: column !important;
  }
  .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
      flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
      flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
      flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
      flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
      flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
      justify-content: flex-start !important;
  }
  .justify-content-sm-end {
      justify-content: flex-end !important;
  }
  .justify-content-sm-center {
      justify-content: center !important;
  }
  .justify-content-sm-between {
      justify-content: space-between !important;
  }
  .justify-content-sm-around {
      justify-content: space-around !important;
  }
  .align-items-sm-start {
      align-items: flex-start !important;
  }
  .align-items-sm-end {
      align-items: flex-end !important;
  }
  .align-items-sm-center {
      align-items: center !important;
  }
  .align-items-sm-baseline {
      align-items: baseline !important;
  }
  .align-items-sm-stretch {
      align-items: stretch !important;
  }
  .align-content-sm-start {
      align-content: flex-start !important;
  }
  .align-content-sm-end {
      align-content: flex-end !important;
  }
  .align-content-sm-center {
      align-content: center !important;
  }
  .align-content-sm-between {
      align-content: space-between !important;
  }
  .align-content-sm-around {
      align-content: space-around !important;
  }
  .align-content-sm-stretch {
      align-content: stretch !important;
  }
  .align-self-sm-auto {
      align-self: auto !important;
  }
  .align-self-sm-start {
      align-self: flex-start !important;
  }
  .align-self-sm-end {
      align-self: flex-end !important;
  }
  .align-self-sm-center {
      align-self: center !important;
  }
  .align-self-sm-baseline {
      align-self: baseline !important;
  }
  .align-self-sm-stretch {
      align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
      flex-direction: row !important;
  }
  .flex-md-column {
      flex-direction: column !important;
  }
  .flex-md-row-reverse {
      flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
      flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
      flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
      flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
      flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
      flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
      flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
      flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
      flex-shrink: 1 !important;
  }
  .justify-content-md-start {
      justify-content: flex-start !important;
  }
  .justify-content-md-end {
      justify-content: flex-end !important;
  }
  .justify-content-md-center {
      justify-content: center !important;
  }
  .justify-content-md-between {
      justify-content: space-between !important;
  }
  .justify-content-md-around {
      justify-content: space-around !important;
  }
  .align-items-md-start {
      align-items: flex-start !important;
  }
  .align-items-md-end {
      align-items: flex-end !important;
  }
  .align-items-md-center {
      align-items: center !important;
  }
  .align-items-md-baseline {
      align-items: baseline !important;
  }
  .align-items-md-stretch {
      align-items: stretch !important;
  }
  .align-content-md-start {
      align-content: flex-start !important;
  }
  .align-content-md-end {
      align-content: flex-end !important;
  }
  .align-content-md-center {
      align-content: center !important;
  }
  .align-content-md-between {
      align-content: space-between !important;
  }
  .align-content-md-around {
      align-content: space-around !important;
  }
  .align-content-md-stretch {
      align-content: stretch !important;
  }
  .align-self-md-auto {
      align-self: auto !important;
  }
  .align-self-md-start {
      align-self: flex-start !important;
  }
  .align-self-md-end {
      align-self: flex-end !important;
  }
  .align-self-md-center {
      align-self: center !important;
  }
  .align-self-md-baseline {
      align-self: baseline !important;
  }
  .align-self-md-stretch {
      align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
      flex-direction: row !important;
  }
  .flex-lg-column {
      flex-direction: column !important;
  }
  .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
      flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
      flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
      flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
      flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
      flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
      justify-content: flex-start !important;
  }
  .justify-content-lg-end {
      justify-content: flex-end !important;
  }
  .justify-content-lg-center {
      justify-content: center !important;
  }
  .justify-content-lg-between {
      justify-content: space-between !important;
  }
  .justify-content-lg-around {
      justify-content: space-around !important;
  }
  .align-items-lg-start {
      align-items: flex-start !important;
  }
  .align-items-lg-end {
      align-items: flex-end !important;
  }
  .align-items-lg-center {
      align-items: center !important;
  }
  .align-items-lg-baseline {
      align-items: baseline !important;
  }
  .align-items-lg-stretch {
      align-items: stretch !important;
  }
  .align-content-lg-start {
      align-content: flex-start !important;
  }
  .align-content-lg-end {
      align-content: flex-end !important;
  }
  .align-content-lg-center {
      align-content: center !important;
  }
  .align-content-lg-between {
      align-content: space-between !important;
  }
  .align-content-lg-around {
      align-content: space-around !important;
  }
  .align-content-lg-stretch {
      align-content: stretch !important;
  }
  .align-self-lg-auto {
      align-self: auto !important;
  }
  .align-self-lg-start {
      align-self: flex-start !important;
  }
  .align-self-lg-end {
      align-self: flex-end !important;
  }
  .align-self-lg-center {
      align-self: center !important;
  }
  .align-self-lg-baseline {
      align-self: baseline !important;
  }
  .align-self-lg-stretch {
      align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
      flex-direction: row !important;
  }
  .flex-xl-column {
      flex-direction: column !important;
  }
  .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
      flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
      flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
      flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
      flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
      flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
      justify-content: flex-start !important;
  }
  .justify-content-xl-end {
      justify-content: flex-end !important;
  }
  .justify-content-xl-center {
      justify-content: center !important;
  }
  .justify-content-xl-between {
      justify-content: space-between !important;
  }
  .justify-content-xl-around {
      justify-content: space-around !important;
  }
  .align-items-xl-start {
      align-items: flex-start !important;
  }
  .align-items-xl-end {
      align-items: flex-end !important;
  }
  .align-items-xl-center {
      align-items: center !important;
  }
  .align-items-xl-baseline {
      align-items: baseline !important;
  }
  .align-items-xl-stretch {
      align-items: stretch !important;
  }
  .align-content-xl-start {
      align-content: flex-start !important;
  }
  .align-content-xl-end {
      align-content: flex-end !important;
  }
  .align-content-xl-center {
      align-content: center !important;
  }
  .align-content-xl-between {
      align-content: space-between !important;
  }
  .align-content-xl-around {
      align-content: space-around !important;
  }
  .align-content-xl-stretch {
      align-content: stretch !important;
  }
  .align-self-xl-auto {
      align-self: auto !important;
  }
  .align-self-xl-start {
      align-self: flex-start !important;
  }
  .align-self-xl-end {
      align-self: flex-end !important;
  }
  .align-self-xl-center {
      align-self: center !important;
  }
  .align-self-xl-baseline {
      align-self: baseline !important;
  }
  .align-self-xl-stretch {
      align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
      float: left !important;
  }
  .float-sm-right {
      float: right !important;
  }
  .float-sm-none {
      float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
      float: left !important;
  }
  .float-md-right {
      float: right !important;
  }
  .float-md-none {
      float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
      float: left !important;
  }
  .float-lg-right {
      float: right !important;
  }
  .float-lg-none {
      float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
      float: left !important;
  }
  .float-xl-right {
      float: right !important;
  }
  .float-xl-none {
      float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 213px;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
table {
  border-collapse: collapse !important;
  width: 100% !important;
}

th, td {
  text-align: center;
  padding: 10px;
  font-weight: 400 !important;
}

tr:nth-child(even){background-color: #D2D2D2}

th {
  background: #1c374b !important;
  color: white !important;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 2px 2px 5px 6px rgba(0, 0, 0, 6%) !important;
  box-shadow: -2px -2px 5px 6px rgba(0, 0, 0, 6%) !important;
}
.shadow {
  box-shadow: 0px 0px 20px 4px #1942321f !important;
}
.shadow-lg {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}

p{
  color: #BCBCBC;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
      margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
      margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
      margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
      margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
      margin-left: 0 !important;
  }
  .m-sm-1 {
      margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
      margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
      margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
      margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
      margin-left: 0.25rem !important;
  }
  .m-sm-2 {
      margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
      margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
      margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
      margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
      margin-left: 0.5rem !important;
  }
  .m-sm-3 {
      margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
      margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
      margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
      margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
      margin-left: 1rem !important;
  }
  .m-sm-4 {
      margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
      margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
      margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
      margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
      margin-left: 1.5rem !important;
  }
  .m-sm-5 {
      margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
      margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
      margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
      margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
      margin-left: 3rem !important;
  }
  .p-sm-0 {
      padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
      padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
      padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
      padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
      padding-left: 0 !important;
  }
  .p-sm-1 {
      padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
      padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
      padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
      padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
      padding-left: 0.25rem !important;
  }
  .p-sm-2 {
      padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
      padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
      padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
      padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
      padding-left: 0.5rem !important;
  }
  .p-sm-3 {
      padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
      padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
      padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
      padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
      padding-left: 1rem !important;
  }
  .p-sm-4 {
      padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
      padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
      padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
      padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
      padding-left: 1.5rem !important;
  }
  .p-sm-5 {
      padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
      padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
      padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
      padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
      padding-left: 3rem !important;
  }
  .m-sm-n1 {
      margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
      margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
      margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
      margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
      margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
      margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
      margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
      margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
      margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
      margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
      margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
      margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
      margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
      margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
      margin-left: -1rem !important;
  }
  .m-sm-n4 {
      margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
      margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
      margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
      margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
      margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
      margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
      margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
      margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
      margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
      margin-left: -3rem !important;
  }
  .m-sm-auto {
      margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
      margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
      margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
      margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
      margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
      margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
      margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
      margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
      margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
      margin-left: 0 !important;
  }
  .m-md-1 {
      margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
      margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
      margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
      margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
      margin-left: 0.25rem !important;
  }
  .m-md-2 {
      margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
      margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
      margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
      margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
      margin-left: 0.5rem !important;
  }
  .m-md-3 {
      margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
      margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
      margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
      margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
      margin-left: 1rem !important;
  }
  .m-md-4 {
      margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
      margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
      margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
      margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
      margin-left: 1.5rem !important;
  }
  .m-md-5 {
      margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
      margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
      margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
      margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
      margin-left: 3rem !important;
  }
  .p-md-0 {
      padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
      padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
      padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
      padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
      padding-left: 0 !important;
  }
  .p-md-1 {
      padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
      padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
      padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
      padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
      padding-left: 0.25rem !important;
  }
  .p-md-2 {
      padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
      padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
      padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
      padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
      padding-left: 0.5rem !important;
  }
  .p-md-3 {
      padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
      padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
      padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
      padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
      padding-left: 1rem !important;
  }
  .p-md-4 {
      padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
      padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
      padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
      padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
      padding-left: 1.5rem !important;
  }
  .p-md-5 {
      padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
      padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
      padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
      padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
      padding-left: 3rem !important;
  }
  .m-md-n1 {
      margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
      margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
      margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
      margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
      margin-left: -0.25rem !important;
  }
  .m-md-n2 {
      margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
      margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
      margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
      margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
      margin-left: -0.5rem !important;
  }
  .m-md-n3 {
      margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
      margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
      margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
      margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
      margin-left: -1rem !important;
  }
  .m-md-n4 {
      margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
      margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
      margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
      margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
      margin-left: -1.5rem !important;
  }
  .m-md-n5 {
      margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
      margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
      margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
      margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
      margin-left: -3rem !important;
  }
  .m-md-auto {
      margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
      margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
      margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
      margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
      margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
      margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
      margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
      margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
      margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
      margin-left: 0 !important;
  }
  .m-lg-1 {
      margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
      margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
      margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
      margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
      margin-left: 0.25rem !important;
  }
  .m-lg-2 {
      margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
      margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
      margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
      margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
      margin-left: 0.5rem !important;
  }
  .m-lg-3 {
      margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
      margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
      margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
      margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
      margin-left: 1rem !important;
  }
  .m-lg-4 {
      margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
      margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
      margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
      margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
      margin-left: 1.5rem !important;
  }
  .m-lg-5 {
      margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
      margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
      margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
      margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
      margin-left: 3rem !important;
  }
  .p-lg-0 {
      padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
      padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
      padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
      padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
      padding-left: 0 !important;
  }
  .p-lg-1 {
      padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
      padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
      padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
      padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
      padding-left: 0.25rem !important;
  }
  .p-lg-2 {
      padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
      padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
      padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
      padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
      padding-left: 0.5rem !important;
  }
  .p-lg-3 {
      padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
      padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
      padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
      padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
      padding-left: 1rem !important;
  }
  .p-lg-4 {
      padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
      padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
      padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
      padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
      padding-left: 1.5rem !important;
  }
  .p-lg-5 {
      padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
      padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
      padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
      padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
      padding-left: 3rem !important;
  }
  .m-lg-n1 {
      margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
      margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
      margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
      margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
      margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
      margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
      margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
      margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
      margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
      margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
      margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
      margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
      margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
      margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
      margin-left: -1rem !important;
  }
  .m-lg-n4 {
      margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
      margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
      margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
      margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
      margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
      margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
      margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
      margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
      margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
      margin-left: -3rem !important;
  }
  .m-lg-auto {
      margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
      margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
      margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
      margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
      margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
      margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
      margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
      margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
      margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
      margin-left: 0 !important;
  }
  .m-xl-1 {
      margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
      margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
      margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
      margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
      margin-left: 0.25rem !important;
  }
  .m-xl-2 {
      margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
      margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
      margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
      margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
      margin-left: 0.5rem !important;
  }
  .m-xl-3 {
      margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
      margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
      margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
      margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
      margin-left: 1rem !important;
  }
  .m-xl-4 {
      margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
      margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
      margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
      margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
      margin-left: 1.5rem !important;
  }
  .m-xl-5 {
      margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
      margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
      margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
      margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
      margin-left: 3rem !important;
  }
  .p-xl-0 {
      padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
      padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
      padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
      padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
      padding-left: 0 !important;
  }
  .p-xl-1 {
      padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
      padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
      padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
      padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
      padding-left: 0.25rem !important;
  }
  .p-xl-2 {
      padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
      padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
      padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
      padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
      padding-left: 0.5rem !important;
  }
  .p-xl-3 {
      padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
      padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
      padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
      padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
      padding-left: 1rem !important;
  }
  .p-xl-4 {
      padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
      padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
      padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
      padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
      padding-left: 1.5rem !important;
  }
  .p-xl-5 {
      padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
      padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
      padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
      padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
      padding-left: 3rem !important;
  }
  .m-xl-n1 {
      margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
      margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
      margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
      margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
      margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
      margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
      margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
      margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
      margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
      margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
      margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
      margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
      margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
      margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
      margin-left: -1rem !important;
  }
  .m-xl-n4 {
      margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
      margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
      margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
      margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
      margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
      margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
      margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
      margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
      margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
      margin-left: -3rem !important;
  }
  .m-xl-auto {
      margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
      margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
      margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
      margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
      margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
      text-align: left !important;
  }
  .text-sm-right {
      text-align: right !important;
  }
  .text-sm-center {
      text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
      text-align: left !important;
  }
  .text-md-right {
      text-align: right !important;
  }
  .text-md-center {
      text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
      text-align: left !important;
  }
  .text-lg-right {
      text-align: right !important;
  }
  .text-lg-center {
      text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
      text-align: left !important;
  }
  .text-xl-right {
      text-align: right !important;
  }
  .text-xl-center {
      text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.dropdown .dropdown-menu .dropdown-header,
.sidebar .sidebar-heading,
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary{
  color: #1c374b;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #224abe !important;
}
.text-secondary {
  color: #858796 !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #60616f !important;
}
.text-success {
  color: #1cc88a !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #13855c !important;
}
.text-info {
  color: #36b9cc !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #258391 !important;
}
.text-warning {
  color: #f6c23e !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #dda20a !important;
}
.text-danger {
  color: #e74a3b !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #be2617 !important;
}
.text-light {
  color: #f8f9fc !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #c2cbe5 !important;
}
.text-dark {
  color: #5a5c69 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #373840 !important;
}
.text-body {
  color: #858796 !important;
}
.text-muted {
  color: #858796 !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
      text-shadow: none !important;
      box-shadow: none !important;
  }
  a:not(.btn) {
      text-decoration: underline;
  }
  abbr[title]::after {
      content: " (" attr(title) ")";
  }
  pre {
      white-space: pre-wrap !important;
  }
  blockquote,
  pre {
      border: 1px solid #b7b9cc;
      page-break-inside: avoid;
  }
  thead {
      display: table-header-group;
  }
  img,
  tr {
      page-break-inside: avoid;
  }
  h2,
  h3,
  p {
      orphans: 3;
      widows: 3;
  }
  h2,
  h3 {
      page-break-after: avoid;
  }
  @page {
      size: a3;
  }
  body {
      min-width: 992px !important;
  }
  .container {
      min-width: 992px !important;
  }
  .navbar {
      display: none;
  }
  .badge {
      border: 1px solid #000;
  }
  .table {
      border-collapse: collapse !important;
  }
  .table td,
  .table th {
      background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
      border: 1px solid #dddfeb !important;
  }
  .table-dark {
      color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
      border-color: #e3e6f0;
  }
  .table .thead-dark th {
      color: inherit;
      border-color: #e3e6f0;
  }
}
html {
  position: relative;
  min-height: 100%;
}
body {
  height: 100%;
}
a:focus {
  outline: 0;
}
#wrapper {
  display: flex;
}
#wrapper #content-wrapper {
  background-color: #F8FCFF;
  width: 100%;
  overflow-x: hidden;
}
#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
}
.scroll-to-top:focus,
.scroll-to-top:hover {
  color: #fff;
}
.scroll-to-top:hover {
  background: #5a5c69;
}
.scroll-to-top i {
  font-weight: 800;
}
@-webkit-keyframes growIn {
  0% {
      transform: scale(0.9);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}
@keyframes growIn {
  0% {
      transform: scale(0.9);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}
.animated--grow-in,
.sidebar .nav-item .collapse {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}
@-webkit-keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}
.bg-gradient-primary {
  background-color: #1c374b;
  background-size: cover;
}
.bg-gradient-secondary {
  background-color: #858796;
  background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
  background-size: cover;
}
.bg-gradient-success {
  background-color: #1cc88a;
  background-image: linear-gradient(180deg, #1cc88a 10%, #13855c 100%);
  background-size: cover;
}
.bg-gradient-info {
  background-color: #36b9cc;
  background-image: linear-gradient(180deg, #36b9cc 10%, #258391 100%);
  background-size: cover;
}
.bg-gradient-warning {
  background-color: #f6c23e;
  background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
  background-size: cover;
}
.bg-gradient-danger {
  background-color: #e74a3b;
  background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%);
  background-size: cover;
}
.bg-gradient-light {
  background-color: #f8f9fc;
  background-image: linear-gradient(180deg, #f8f9fc 10%, #c2cbe5 100%);
  background-size: cover;
}
.bg-gradient-dark {
  background-color: #5a5c69;
  background-image: linear-gradient(180deg, #5a5c69 10%, #373840 100%);
  background-size: cover;
}
.bg-gray-100 {
  background-color: #f8f9fc !important;
}
.bg-gray-200 {
  background-color: #eaecf4 !important;
}
.bg-gray-300 {
  background-color: #dddfeb !important;
}
.bg-gray-400 {
  background-color: #d1d3e2 !important;
}
.bg-gray-500 {
  background-color: #b7b9cc !important;
}
.bg-gray-600 {
  background-color: #858796 !important;
}
.bg-gray-700 {
  background-color: #6e707e !important;
}
.bg-gray-800 {
  background-color: #5a5c69 !important;
}
.bg-gray-900 {
  background-color: #3a3b45 !important;
}
.o-hidden {
  overflow: hidden !important;
}
.text-xs {
  font-size: 0.7rem;
}
.text-lg {
  font-size: 1.2rem;
}
.text-gray-100 {
  color: #f8f9fc !important;
}
.text-gray-200 {
  color: #eaecf4 !important;
}
.text-gray-300 {
  color: #dddfeb !important;
}
.text-gray-400 {
  color: #d1d3e2 !important;
}
.text-gray-500 {
  color: #b7b9cc !important;
}
.text-gray-600 {
  color: #858796 !important;
}
.text-gray-700 {
  color: #6e707e !important;
}
.text-gray-800 {
  color: #5a5c69 !important;
}
.text-gray-900 {
  color: #3a3b45 !important;
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-left-primary {
  border-left: 0.25rem solid #1c374b !important;
}
.border-bottom-primary {
  border-bottom: 0.25rem solid #1c374b !important;
}
.border-left-secondary {
  border-left: 0.25rem solid #858796 !important;
}
.border-bottom-secondary {
  border-bottom: 0.25rem solid #858796 !important;
}
.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}
.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a !important;
}
.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}
.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc !important;
}
.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}
.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e !important;
}
.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}
.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b !important;
}
.border-left-light {
  border-left: 0.25rem solid #f8f9fc !important;
}
.border-bottom-light {
  border-bottom: 0.25rem solid #f8f9fc !important;
}
.border-left-dark {
  border-left: 0.25rem solid #5a5c69 !important;
}
.border-bottom-dark {
  border-bottom: 0.25rem solid #5a5c69 !important;
}
.progress-sm {
  height: 0.5rem;
}
.rotate-15 {
  transform: rotate(15deg);
}
.rotate-n-15 {
  transform: rotate(-15deg);
}
.dropdown .dropdown-menu {
  font-size: 0.85rem;
}
.dropdown .dropdown-menu .dropdown-header {
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}
.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}
.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}
.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}
.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}
.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: -0.25rem;
}
.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}
.topbar {
  height: 4.375rem;
}
.topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
}
.topbar #sidebarToggleTop:hover {
  background-color: #eaecf4;
}
.topbar #sidebarToggleTop:active {
  background-color: #dddfeb;
}
.topbar .navbar-search {
  margin-left: 5%;
  width: 12rem;
}
.topbar .navbar-search input {
  font-size: 0.85rem;
  height: auto;
}
.topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}
.topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}
.topbar .nav-item .nav-link:focus {
  outline: 0;
}
.topbar .nav-item:focus {
  outline: 0;
}
.topbar .dropdown {
  position: static;
}
.topbar .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
}
.topbar .dropdown-list {
  padding: 0;
  border: none;
  overflow: hidden;
}
.topbar .dropdown-list .dropdown-header {
  background-color: #1c374b;
  border: 1px solid #1c374b;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
}
.topbar .dropdown-list .dropdown-item {
  white-space: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 1px solid #e3e6f0;
  border-right: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
  background-color: #eaecf4;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}
.topbar .dropdown-list .dropdown-item .text-truncate {
  max-width: 10rem;
}
.topbar .dropdown-list .dropdown-item:active {
  background-color: #eaecf4;
  color: #3a3b45;
}
@media (min-width: 576px) {
  .topbar .dropdown {
      position: relative;
  }
  .topbar .dropdown .dropdown-menu {
      width: auto;
      right: 0;
  }
  .topbar .dropdown-list {
      width: 20rem !important;
  }
  .topbar .dropdown-list .dropdown-item .text-truncate {
      max-width: 13.375rem;
  }
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #fff;
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
  color: #fff;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #d1d3e2;
  font-size: 13px;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #b7b9cc;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: #858796;
}
.sidebar {
  width: 8.5rem;
  min-height: 100vh;
}
.sidebar .nav-item {
  position: relative;
}
.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}
.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 6.5rem;
}
.sidebar .nav-item .nav-link span {
  font-size: 14px;
  display: inline;
}
.sidebar .nav-item.active .nav-link {
  font-weight: 700;
}
.sidebar .nav-item .collapse {
  position: absolute;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}
.sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}
.sidebar .nav-item .collapsing {
  display: none;
  transition: none;
}
.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
  padding: 0.5rem 0;
  min-width: 10rem;
  font-size: 0.85rem;
  margin: 0 0 1rem 0;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #eaecf4;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
  background-color: #dddfeb;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #1c374b;
  font-weight: 700;
}
.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}
.sidebar #sidebarToggle::after {
  font-weight: 900;
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  margin-right: 0.1rem;
}
.sidebar #sidebarToggle:hover {
  text-decoration: none;
}
.sidebar #sidebarToggle:focus {
  outline: 0;
}
.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}
.sidebar.toggled #sidebarToggle::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  margin-left: 0.25rem;
}
.sidebar.toggled .sidebar-card {
  display: none;
}
.sidebar .sidebar-brand {
  margin-bottom: 24px;
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
}
.sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}
.sidebar .sidebar-brand .sidebar-brand-text {
  display: none;
}
.sidebar hr.sidebar-divider {
  margin: 0 1rem 1rem;
}
.sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}
.sidebar .sidebar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.35rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .sidebar-card .sidebar-card-illustration {
  height: 3rem;
  display: block;
}
.sidebar .sidebar-card .sidebar-card-title {
  font-weight: 700;
}
.sidebar .sidebar-card p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 768px) {
  .sidebar {
      width: 16rem !important;
  }
  .sidebar .nav-item .collapse {
      position: relative;
      left: 0;
      z-index: 1;
      top: 0;
      -webkit-animation: none;
      animation: none;
  }
  .sidebar .nav-item .collapse .collapse-inner {
      border-radius: 0;
      box-shadow: none;
  }
  .sidebar .nav-item .collapsing {
      display: block;
      transition: height 0.15s ease;
  }
  .sidebar .nav-item .collapse,
  .sidebar .nav-item .collapsing {
      margin: 0 1rem;
  }
  .sidebar .nav-item .nav-link {
      display: block;
      width: 100%;
      text-align: left;
      padding: 1rem;
      width: 14rem;
  }
  .sidebar .nav-item .nav-link i {
      font-size: 0.85rem;
      margin-right: 0.25rem;
  }
  .sidebar .nav-item .nav-link span {
      font-size: 14px;
      display: inline;
  }
  .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
      width: 1rem;
      text-align: center;
      float: right;
      vertical-align: 0;
      border: 0;
      font-weight: 900;
      content: "\f107";
      font-family: "Font Awesome 5 Free";
  }
  .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
      content: "\f105";
  }
  .sidebar .sidebar-brand .sidebar-brand-icon i {
      font-size: 2rem;
  }
  .sidebar .sidebar-brand .sidebar-brand-text {
      display: inline;
  }
  .sidebar .sidebar-heading {
      text-align: left;
  }
  .sidebar.toggled {
      overflow: visible;
      width: 6.5rem !important;
  }
  .sidebar.toggled .nav-item .collapse {
      position: absolute;
      left: calc(6.5rem + 1.5rem / 2);
      z-index: 1;
      top: 2px;
      -webkit-animation-name: growIn;
      animation-name: growIn;
      -webkit-animation-duration: 0.2s;
      animation-duration: 0.2s;
      -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
      animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  }
  .sidebar.toggled .nav-item .collapse .collapse-inner {
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
      border-radius: 0.35rem;
  }
  .sidebar.toggled .nav-item .collapsing {
      display: none;
      transition: none;
  }
  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
      margin: 0;
  }
  .sidebar.toggled .nav-item:last-child {
      margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
      text-align: center;
      padding: 0.75rem 1rem;
      width: 6.5rem;
  }
  .sidebar.toggled .nav-item .nav-link span {
      font-size: 0.65rem;
      display: block;
  }
  .sidebar.toggled .nav-item .nav-link i {
      margin-right: 0;
  }
  .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
      display: none;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
      font-size: 2rem;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-text {
      display: none;
  }
  .sidebar.toggled .sidebar-heading {
      text-align: center;
  }
}
.sidebar-light .sidebar-brand {
  color: #6e707e;
}
.sidebar-light hr.sidebar-divider {
  border-top: 1px solid #eaecf4;
}
.sidebar-light .sidebar-heading {
  color: #b7b9cc;
}
.sidebar-light .nav-item .nav-link {
  color: #858796;
}
.sidebar-light .nav-item .nav-link i {
  color: #d1d3e2;
}
.sidebar-light .nav-item .nav-link:active,
.sidebar-light .nav-item .nav-link:focus,
.sidebar-light .nav-item .nav-link:hover {
  color: #6e707e;
}
.sidebar-light .nav-item .nav-link:active i,
.sidebar-light .nav-item .nav-link:focus i,
.sidebar-light .nav-item .nav-link:hover i {
  color: #6e707e;
}
.sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
  color: #b7b9cc;
}
.sidebar-light .nav-item.active .nav-link {
  color: #6e707e;
}
.sidebar-light .nav-item.active .nav-link i {
  color: #6e707e;
}
.sidebar-light #sidebarToggle {
  background-color: #eaecf4;
}
.sidebar-light #sidebarToggle::after {
  color: #b7b9cc;
}
.sidebar-light #sidebarToggle:hover {
  background-color: #dddfeb;
}
.sidebar-dark .sidebar-brand {
  color: #fff;
}
.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.sidebar-dark .sidebar-heading {
  color: rgba(255, 255, 255, 0.4);
}
.sidebar-dark .nav-item .nav-link {
  color: rgba(255, 255, 255, 1);
}
.sidebar-dark .nav-item .nav-link i {
  color: rgba(255, 255, 255, 1);
}
.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus,
.sidebar-dark .nav-item .nav-link:hover {
  color: #fff;
}
.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
  color: #fff;
}
.sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark .nav-item.active .nav-link {
  color: #fff;
}
.sidebar-dark .nav-item.active .nav-link i {
  color: #fff;
}
.sidebar-dark #sidebarToggle {
  background-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark #sidebarToggle:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.sidebar-dark.toggled #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}
.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.75rem;
}
.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.35rem;
}
.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
}
.btn-icon-split .icon {
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  padding: 0.375rem 0.75rem;
}
.btn-icon-split .text {
  display: inline-block;
  padding: 0.375rem 0.75rem;
}
.btn-group-sm > .btn-icon-split.btn .icon,
.btn-icon-split.btn-sm .icon {
  padding: 0.25rem 0.5rem;
}
.btn-group-sm > .btn-icon-split.btn .text,
.btn-icon-split.btn-sm .text {
  padding: 0.25rem 0.5rem;
}
.btn-group-lg > .btn-icon-split.btn .icon,
.btn-icon-split.btn-lg .icon {
  padding: 0.5rem 1rem;
}
.btn-group-lg > .btn-icon-split.btn .text,
.btn-icon-split.btn-lg .text {
  padding: 0.5rem 1rem;
}
.card .card-header .dropdown {
  line-height: 1;
}
.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}
.card .card-header[data-toggle="collapse"] {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}
.card .card-header[data-toggle="collapse"]::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  line-height: 51px;
  font-weight: 900;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  color: #d1d3e2;
}
.card .card-header[data-toggle="collapse"].collapsed {
  border-radius: 0.35rem;
}
.card .card-header[data-toggle="collapse"].collapsed::after {
  content: "\f105";
}
.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-area {
      height: 8rem;
  }
}
.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-bar {
      height: 20rem;
  }
}
.chart-pie {
  position: relative;
  height: 15rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-pie {
      height: calc(20rem - 43px) !important;
  }
}
.imgpr {
  margin-top: 120px;
  width: 90%;
  height: auto;
  margin-left: 28px;
}
.bg-login-image {
  background-position: center;
  background-size: cover;
}
.bg-register-image {
  background-position: center;
  background-size: cover;
}
.bg-password-image {
  background-position: center;
  background-size: cover;
}
form.user .custom-checkbox.small label {
  line-height: 1.5rem;
}
form.user .form-control-user {
  font-size: 0.8rem;
  border-radius: 4px;
  padding: 1.5rem 1rem;
}
form.user .btn-user {
  font-size: 0.8rem;
  border-radius: 4px;
  padding: 0.75rem 1rem;
}
.btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
.btn-google:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
}
.btn-google.focus,
.btn-google:focus {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-google.disabled,
.btn-google:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
.btn-google:not(:disabled):not(.disabled).active,
.btn-google:not(:disabled):not(.disabled):active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #dfdfdf;
}
.btn-google:not(:disabled):not(.disabled).active:focus,
.btn-google:not(:disabled):not(.disabled):active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
}
.btn-facebook.focus,
.btn-facebook:focus {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #dfdfdf;
}
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}
@-webkit-keyframes noise-anim {
  0% {
      clip: rect(49px, 9999px, 40px, 0);
  }
  5% {
      clip: rect(75px, 9999px, 72px, 0);
  }
  10% {
      clip: rect(97px, 9999px, 93px, 0);
  }
  15% {
      clip: rect(15px, 9999px, 9px, 0);
  }
  20% {
      clip: rect(14px, 9999px, 92px, 0);
  }
  25% {
      clip: rect(18px, 9999px, 94px, 0);
  }
  30% {
      clip: rect(17px, 9999px, 20px, 0);
  }
  35% {
      clip: rect(71px, 9999px, 59px, 0);
  }
  40% {
      clip: rect(42px, 9999px, 84px, 0);
  }
  45% {
      clip: rect(56px, 9999px, 25px, 0);
  }
  50% {
      clip: rect(46px, 9999px, 14px, 0);
  }
  55% {
      clip: rect(47px, 9999px, 1px, 0);
  }
  60% {
      clip: rect(64px, 9999px, 58px, 0);
  }
  65% {
      clip: rect(89px, 9999px, 92px, 0);
  }
  70% {
      clip: rect(56px, 9999px, 39px, 0);
  }
  75% {
      clip: rect(80px, 9999px, 71px, 0);
  }
  80% {
      clip: rect(8px, 9999px, 13px, 0);
  }
  85% {
      clip: rect(66px, 9999px, 68px, 0);
  }
  90% {
      clip: rect(68px, 9999px, 4px, 0);
  }
  95% {
      clip: rect(56px, 9999px, 14px, 0);
  }
  100% {
      clip: rect(28px, 9999px, 53px, 0);
  }
}
@keyframes noise-anim {
  0% {
      clip: rect(49px, 9999px, 40px, 0);
  }
  5% {
      clip: rect(75px, 9999px, 72px, 0);
  }
  10% {
      clip: rect(97px, 9999px, 93px, 0);
  }
  15% {
      clip: rect(15px, 9999px, 9px, 0);
  }
  20% {
      clip: rect(14px, 9999px, 92px, 0);
  }
  25% {
      clip: rect(18px, 9999px, 94px, 0);
  }
  30% {
      clip: rect(17px, 9999px, 20px, 0);
  }
  35% {
      clip: rect(71px, 9999px, 59px, 0);
  }
  40% {
      clip: rect(42px, 9999px, 84px, 0);
  }
  45% {
      clip: rect(56px, 9999px, 25px, 0);
  }
  50% {
      clip: rect(46px, 9999px, 14px, 0);
  }
  55% {
      clip: rect(47px, 9999px, 1px, 0);
  }
  60% {
      clip: rect(64px, 9999px, 58px, 0);
  }
  65% {
      clip: rect(89px, 9999px, 92px, 0);
  }
  70% {
      clip: rect(56px, 9999px, 39px, 0);
  }
  75% {
      clip: rect(80px, 9999px, 71px, 0);
  }
  80% {
      clip: rect(8px, 9999px, 13px, 0);
  }
  85% {
      clip: rect(66px, 9999px, 68px, 0);
  }
  90% {
      clip: rect(68px, 9999px, 4px, 0);
  }
  95% {
      clip: rect(56px, 9999px, 14px, 0);
  }
  100% {
      clip: rect(28px, 9999px, 53px, 0);
  }
}
.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}
@-webkit-keyframes noise-anim-2 {
  0% {
      clip: rect(16px, 9999px, 10px, 0);
  }
  5% {
      clip: rect(22px, 9999px, 29px, 0);
  }
  10% {
      clip: rect(6px, 9999px, 68px, 0);
  }
  15% {
      clip: rect(85px, 9999px, 95px, 0);
  }
  20% {
      clip: rect(65px, 9999px, 91px, 0);
  }
  25% {
      clip: rect(93px, 9999px, 68px, 0);
  }
  30% {
      clip: rect(10px, 9999px, 27px, 0);
  }
  35% {
      clip: rect(37px, 9999px, 25px, 0);
  }
  40% {
      clip: rect(12px, 9999px, 23px, 0);
  }
  45% {
      clip: rect(40px, 9999px, 18px, 0);
  }
  50% {
      clip: rect(19px, 9999px, 71px, 0);
  }
  55% {
      clip: rect(2px, 9999px, 35px, 0);
  }
  60% {
      clip: rect(16px, 9999px, 69px, 0);
  }
  65% {
      clip: rect(8px, 9999px, 65px, 0);
  }
  70% {
      clip: rect(30px, 9999px, 57px, 0);
  }
  75% {
      clip: rect(14px, 9999px, 4px, 0);
  }
  80% {
      clip: rect(39px, 9999px, 30px, 0);
  }
  85% {
      clip: rect(22px, 9999px, 35px, 0);
  }
  90% {
      clip: rect(58px, 9999px, 71px, 0);
  }
  95% {
      clip: rect(34px, 9999px, 90px, 0);
  }
  100% {
      clip: rect(67px, 9999px, 68px, 0);
  }
}
@keyframes noise-anim-2 {
  0% {
      clip: rect(16px, 9999px, 10px, 0);
  }
  5% {
      clip: rect(22px, 9999px, 29px, 0);
  }
  10% {
      clip: rect(6px, 9999px, 68px, 0);
  }
  15% {
      clip: rect(85px, 9999px, 95px, 0);
  }
  20% {
      clip: rect(65px, 9999px, 91px, 0);
  }
  25% {
      clip: rect(93px, 9999px, 68px, 0);
  }
  30% {
      clip: rect(10px, 9999px, 27px, 0);
  }
  35% {
      clip: rect(37px, 9999px, 25px, 0);
  }
  40% {
      clip: rect(12px, 9999px, 23px, 0);
  }
  45% {
      clip: rect(40px, 9999px, 18px, 0);
  }
  50% {
      clip: rect(19px, 9999px, 71px, 0);
  }
  55% {
      clip: rect(2px, 9999px, 35px, 0);
  }
  60% {
      clip: rect(16px, 9999px, 69px, 0);
  }
  65% {
      clip: rect(8px, 9999px, 65px, 0);
  }
  70% {
      clip: rect(30px, 9999px, 57px, 0);
  }
  75% {
      clip: rect(14px, 9999px, 4px, 0);
  }
  80% {
      clip: rect(39px, 9999px, 30px, 0);
  }
  85% {
      clip: rect(22px, 9999px, 35px, 0);
  }
  90% {
      clip: rect(58px, 9999px, 71px, 0);
  }
  95% {
      clip: rect(34px, 9999px, 90px, 0);
  }
  100% {
      clip: rect(67px, 9999px, 68px, 0);
  }
}
.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #1c374b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
footer.sticky-footer {
  padding: 1rem 0;
  flex-shrink: 0;
}
footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}
body.sidebar-toggled footer.sticky-footer {
  width: 100%;
}
.bg-login {
  background-color: #f3f6f8;
  background-size: cover;
}
.bg-card {
  background-color: #1c374b;
}
.w-height {
  align-items: center;
  height: 100vh;
}
